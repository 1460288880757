import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import users from './modules/users'
import auth from './modules/auth'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // API_URL: 'http://api3.evrotrans.test',
        // AUTH_URL: 'http://api3.evrotrans.test/auth',
        AUTH_URL: 'https://api3.evrotrans.net/auth',
        API_URL: 'https://api3.evrotrans.net',

    },
    // getters: {
    //     API_URL: 'http://api3.evrotrans.test',
    // },
    modules: {
        app,
        users,
        auth
    }

})