import { Store } from "vuex";
import Cookies from 'js-cookie'
import axios from "axios";

export default {
    state: {
        darkTheme: true,
        background: "-1",
        drawer: true,
        dense: true,
        miniVariant: false,
        menuItems: [
            // { title: 'Пользователи', icon: 'fa-users', to: '/users', childe: [] },
            // { title: 'Ведомости', icon: 'fa-file-text', to: '/vedomosti', childe: [] },
            // { title: 'Транспорт', icon: 'fa-bus', to: '/buses', childe: [] },
            // {
            //   title: 'Рейсы',icon: 'fa-map',to: '',
            //     childe: [
            //         ['Рейсы', 'fa-map', '/reisy'],
            //         ['Остановочные пункты', 'fa-location-dot', '/destination']
            //     ]
            // },
        ],
    },
    mutations: {
        setDarkTheme(state) {
            state.background = "-1";
            state.darkTheme = true;
        },
        setLightTheme(state) {
            state.background = "-2";
            state.darkTheme = false;
        },
        setDrawer(state) {
            state.drawer = !state.drawer;
        },
        setDrawerTrue(state) {
            state.drawer = true;
        },
        setMiniVariant(state) {
            state.miniVariant = !state.miniVariant;
        },
        updateMenu(state, { modules, perm }) {
            // {
            //     "bit": 2,
            //     "parent_bit": 0,
            //     "name": "users",
            //     "info": "Пользователи",
            //     "invisible": 0,
            //     "fa_icon": "fa-users"
            // },

            //  && i.parent_bit === 0

            let childeMenu = modules.filter(i => ((parseInt(i.bit, 10) & parseInt(perm, 10)) === i.bit && i.invisible === 0 && i.parent_bit > 0 && i.bit > 1))
            state.menuItems = modules.filter(i => ((parseInt(i.bit, 10) & parseInt(perm, 10)) === i.bit && i.invisible === 0 && i.parent_bit === 0 && i.bit > 1))

            state.menuItems = state.menuItems.map(i => {
                    let child = childeMenu.filter(childe => (childe.parent_bit === i.bit))
                    let childs = child.map(c => {
                        return [c.info, c.fa_icon, '/' + c.name]
                    })
                    let it = { title: i.info, icon: i.fa_icon, to: '/' + i.name, childe: [] };
                    if (childs.length > 0) {
                        childs.unshift([i.info, i.fa_icon, '/' + i.name])
                        it = { title: i.info, icon: i.fa_icon, to: '/' + i.name, childe: childs };
                    }

                    return it
                })
                //console.log(state.menuItems);
        }
    },
    actions: {
        actionDrawer(ctx) {
            ctx.commit('setDrawer')
        },
        actionMiniVariant(ctx) {
            ctx.commit('setMiniVariant')
        },
        actionDrawerTrue(ctx) {
            ctx.commit('setDrawerTrue')
        },
        async getMenu(ctx) {

            await ctx.dispatch('refreshToken');
            let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';
            // console.log(jwt)

            const res = await fetch(ctx.rootState.API_URL + "/my-modules", {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Cookie': 'Cookie (en-US)',
                    'Authorization': "Bearer " + jwt
                },
            });

            // const res = await axios({
            //     method: 'GET',
            //     url: ctx.rootState.API_URL + "/my-modules",
            //     // data: authdate,
            //     // credentials: true,
            //     // exposedHeaders: ["set-cookie"],
            //     headers: {
            //         "Content-type": "application/json; charset=UTF-8",
            //         'Authorization': "Bearer " + jwt,
            //     }
            // });
            const modules = await res.json();

            let testDate = {
                modules: modules.items,
                perm: ctx.getters.permUser
            };
            ctx.commit('updateMenu', testDate);
        },
    },
    getters: {
        menuItems(state) {
            return state.menuItems
        },
        drawer(state) {
            return state.drawer
        },
        miniVariant(state) {
            return state.miniVariant
        },
        darkTheme(state) {
            return state.darkTheme
        },
        dense(state) {
            return state.dense
        },


    },

}