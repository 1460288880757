import axios from "axios";
import Cookies from 'js-cookie'

export default {
    state: {
        users: [],
        modulesArray: [
            // {"bit":"1","parent_bit":"0","name":"root","info":"root"},
            // {"bit":"2","parent_bit":"0","name":"users","info":"Пользователи"},
        ],
    },
    mutations: {
        updateUsersList(state, users) {
            //console.log(tasks[0]);
            state.users = users.map(i => {
                i.reveal = false;
                return i
            })
        },
        updateModules(state, Modules) {
            //console.log(tasks[0]);
            state.modulesArray = Modules

        },
    },
    actions: {
        async getUsers(ctx) {
            // console.log(ctx.rootState.API_URL) 
            let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';
            const res = await fetch(ctx.rootState.API_URL + "/my-users", {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + jwt
                },
            });
            const users = await res.json();

            ctx.commit('updateUsersList', users.items)
        },
        async getModules(ctx) {
            let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';
            const res = await fetch(ctx.rootState.API_URL + "/my-modules", {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + jwt

                },
            });
            const modules = await res.json();
            ctx.commit('updateModules', modules.items)
        },

    },
    modules: {},

    getters: {
        allUsers(state) {
            return state.users
        },
        modulesArray(state) {
            return state.modulesArray
        },

    },

}