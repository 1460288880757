import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);


let date = new Date();
let darkthem = (date.getHours() < 7 || date.getHours() > 20) ? true : false

export default new Vuetify({


    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: darkthem,
        themes: {
            light: {
                primary: colors.blue,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blue.lighten1,
            }
        }
    }

});