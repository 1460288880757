<template>
 <v-app>
    <Sidebar/>
    <Navbar/>

    <v-main class="ml-2">
      <router-view/>
       <theme-changer/>
    </v-main>
  </v-app> 
</template>

<script>
import themeChanger from '@/components/themeChanger.vue';
import Navbar from '@/components/app/Navbar.vue'
import Sidebar from '@/components/app/Sidebar.vue'
export default {
  components: { themeChanger,Sidebar,Navbar },
  
};
</script>
