<template>
    <v-navigation-drawer
        v-model="drawer"
        app="app"
        dark="dark"
        :dense="dense"
    >
        <!-- <router-link
            tag="a"
            to="/"
            exact="exact"
            class="nav-link"
            active-class="active"
            aria-current="page"> -->
            <v-img
                alt="Logo"
                class="shrink ml-3 mt-2 mb-5"
                contain="contain"
                src="/img/logo.png"
                transition="scale-transition"
                height="30px"/>
        <!-- </router-link> -->

        <v-list nav="nav" :dense="dense">
        <template v-for="(item, i) in menuItems">
          <v-list-item  v-if="!item.childe.length"
            :key="i"
            :to="item.to"
            router
            exact
            :title="item.title"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>

          <v-list-group v-if="item.childe.length"
            :key="i"
            :value="false"
            :prepend-icon="item.icon"
            color="indigo lighten-4"
            >
     
            <template v-slot:activator>
              
              <v-list-item-title>{{item.title}}</v-list-item-title>
              
            </template>
            <v-list-item
                v-for="([title,icon,to], s) in item.childe"
                :key="s"
                :to="to"
                link
                class="pl-7 pr-4"
                :title="title"
                
              ><v-list-item-icon>
              <v-icon small>{{ icon }}</v-icon>
            </v-list-item-icon>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
              
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {
  name: 'Sidebar',
  data: () => ({

  }),

  computed:{
    ...mapGetters(['menuItems','drawer','miniVariant','dense']),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(v) {
        return this.$store.getters.drawer;
      }
    }
  },
  
  methods: {
    ...mapActions(['actionDrawer','actionMiniVariant','getMenu']),

   },
  
  async mounted(){
    this.getMenu();
  }



}
</script>
<style scoped>
 @media (max-width:400px){
   .vi{
     display: none;
   }
  }
</style>