<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
export default {
  components:{
    EmptyLayout, MainLayout
  },
  computed:{
    layout(){
      //console.log(this.$route.meta)
      //return 'EmptyLayout'
      return (this.$route.meta.layout ||'EmptyLayout')
    }
  }
  
};
</script>
<style>

</style>