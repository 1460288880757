import axios from 'axios'
import Cookies from 'js-cookie'


export default {
    state: {
        okKey: false,
        permUser: 0,
    },
    actions: {

        async login({ rootState, dispatch, commit }, { name, password }) {
            try {
                let authdate = {
                    login: name,
                    password: password
                };
                // console.log(rootState)
                await axios({
                        method: 'post',
                        url: rootState.AUTH_URL + '/login',
                        data: authdate,
                        credentials: 'include',
                        // credentials: true,
                        // exposedHeaders: ["set-cookie"],
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                                // "Access-Control-Allow-Headers": "set-cookie",
                                // 'credentials': true,
                                // 'exposedHeaders': ["set-cookie"],
                                // "Cookie": "pageAccess=2"
                        }
                    })
                    .then((response) => {

                        if (+response.data.error === 0) {
                            console.log(response)
                            Cookies.set('jwt', response.data.token, { expires: 7 })
                            commit('trueKey', response.data.perm)
                        }
                        if (+response.data.error > 0) {
                            // this.showMessage = true;
                            // this.message = response.data.message
                        }

                    })

            } catch (e) {
                throw e
            }
        },
        async refreshToken({ rootState, dispatch, commit }) {
            try {
                return true;
                // console.log(rootState)
                await axios({
                        method: 'post',
                        url: rootState.AUTH_URL + '/refresh-token',
                        // data: authdate,
                        credentials: 'include',
                        // credentials: true,
                        // exposedHeaders: ["set-cookie"],
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            // 'Cookie': 'Cookie (en-US)',
                            // "Access-Control-Allow-Headers": "set-cookie",
                            // 'credentials': true,
                            // 'exposedHeaders': ["set-cookie"],
                            // "Cookie": "pageAccess=2"
                        }
                    })
                    .then((response) => {

                        if (+response.data.error === 0) {
                            console.log(response)
                            Cookies.set('jwt', response.data.token, { expires: 7, secure: true })
                            commit('trueKey', response.data.perm)
                        }
                        if (+response.data.error > 0) {
                            // this.showMessage = true;
                            // this.message = response.data.message
                        }

                    })

            } catch (e) {
                throw e
            }
        },
        async registerUser({ rootState, dispatch, commit }, newUser) {
            let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';
            try {
                await axios({
                    method: 'POST',
                    url: rootState.API_URL,
                    data: newUser,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        'Authorization': "Bearer " + jwt
                    }
                })

            } catch (e) {
                throw e
            }

        },
        async updateUser({ rootState, dispatch, commit }, editUser) {

            try {
                let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';

                editUser.dostup = String(editUser.chips.reduce(function(sum, current) {
                    return sum + current;
                }, 0))
                await axios({
                    method: 'PATCH',
                    url: rootState.API_URL + '/my-users/' + editUser.id,
                    data: editUser,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        'Authorization': "Bearer " + jwt

                    }
                })

            } catch (e) {
                throw e
            }

        },
        async checkToken({ rootState, dispatch, commit }) {

            commit('trueKey', '32767')
            return true

            let jwt = !!Cookies.get('jwt') ? Cookies.get('jwt') : '';
            let token = {
                jwt: jwt
            };
            await axios({
                    method: 'post',
                    url: rootState.AUTH_URL + '/validate_token.php',
                    data: token,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then((response) => {
                    //console.log("ответа сервера:  " + response.data.message)
                    commit('trueKey', response.data.perm)
                    return true
                })
                .catch((e) => {
                    //console.log("ответа сервера:  " + e.response.data.message)
                    commit('trueKey', '32767')
                    return true

                    commit('falseKey')
                    return false
                })



        },
        logouts({ dispatch, commit }) {
            commit('falseKey')
            return true
        }
    },

    mutations: {
        trueKey(state, perm) {
            state.okKey = true
            state.permUser = perm
        },
        falseKey(state) {
            state.okKey = false
            state.permUser = 0
        }

    },
    getters: {
        permUser(state) {
            return state.permUser
        },
    }
}