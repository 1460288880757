import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'
import Cookies from 'js-cookie'


Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "login",
        meta: { layout: "EmptyLayout" },
        component: () =>
            import ("../views/LoginView.vue"),
    },
    {
        path: "/",
        name: "home",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/HomeView.vue"),
    },
    {
        path: "/about",
        name: "about",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/AboutView.vue"),
    },
    {
        path: "/users",
        name: "users",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/UsersView.vue"),
    },
    {
        path: "/vedomosti",
        name: "vedomosti",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/VedomostiView.vue"),
    },
    {
        path: "/buses",
        name: "buses",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/BusesView.vue"),
    },
    {
        path: "/reisy",
        name: "reisy",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/ReisyView.vue"),
    },
    {
        path: "/destination",
        name: "destination",
        meta: { layout: "MainLayout", auth: true },
        component: () =>
            import ("../views/DestinationView.vue"),
    },
    {
        path: "/statistic",
        name: "statistic",
        meta: { layout: "EmptyLayout" },
        component: () =>
            import ("../views/StatisticView.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async(to, from, next) => {
    const LoggedIn = !!Cookies.get('jwt')
    if (LoggedIn) {
        await store.dispatch('checkToken')
    }
    const requireAuth = to.matched.some(record => record.meta.auth)
    const patchLogin = to.matched.find(record => record.name === 'login')

    if (requireAuth && (!LoggedIn)) {
        next('/login?message=login')
    } else {
        if (patchLogin && LoggedIn && store.state.auth.okKey) {
            next('/')
        } else {
            next()
        }
        if (requireAuth && !store.state.auth.okKey) {
            next('/login?message=relogin')
        }
    }


})

export default router;