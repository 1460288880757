<template>
    <v-app-bar app="app" color="#3c8dbc" dense="dense">
        <!-- clipped-left -->
        <v-app-bar-nav-icon @click="actionDrawer"></v-app-bar-nav-icon>
        
        <v-spacer></v-spacer>

        <v-tooltip bottom="bottom">
            <template v-slot:activator="{ on: tooltip }">
                <v-btn 
                  text="text" 
                  v-on="{ ...tooltip }"
                  @click="logout"
                >
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
            </template>
            <span>Выйти из системы</span>
        </v-tooltip>
    </v-app-bar>
    
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Navbar',
  data: () => ({
  
  }),
  computed: mapGetters(['items','drawer','miniVariant','dense']),
   methods: {
    ...mapActions(['actionDrawer','actionMiniVariant','actionDrawerTrue','actionRemoveJWT','logouts']),
    logout(){
      Cookies.remove('jwt')
      this.logouts()
      this.$router.push('/login')
    }
   }
}
</script>
<style scoped>


 @media (max-width:2900px){
   .vim{
     display: block;
   }
   .vid{
     display: none;
   }
  } 
@media (min-width:400px){
   .vim{
     display: none;
   }
   .vid{
     display: block;
   }
  }
</style>