<template>
  <div class="switchTheme">
    <v-btn class="mx-2" fab @click="changeTheme" color="primary" >
      <v-icon dark>mdi-invert-colors</v-icon>
    </v-btn>
  </div>
</template>

<script>
let date = new Date();
let darkthem = (date.getHours() < 7 || date.getHours() > 20) ? true : false;

export default {
  data: function () {
    return {
      darkTheme: darkthem,
      
    };
    
  },
  methods: {
    changeTheme() {
        
      if (this.darkTheme == false) {
        this.$store.commit("setDarkTheme");
        this.$vuetify.theme.dark = true;
        this.darkTheme = true;
      } else {
        this.$store.commit("setLightTheme");
        this.$vuetify.theme.dark = false;
        this.darkTheme = false;
      }
    },
  },
};
</script>
<style scoped>
.switchTheme {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}
</style>